<template>
  <div class="d-flex justify-content-center">
    <div class="invoice-parent">
      <div class="d-flex justify-content-center parent-container animate__animated animate__fadeIn">

        <div class="d-flex flex-column">
          <div class="intro-container my-container-light p-3 d-flex">

            <div>
              <b-icon class="big-reclamatec-icon" icon="award"></b-icon>

            </div>
            <div>

              <h5 class="claim-title" tabindex="0">Fin de la reclamación</h5>
              <p class="claim-text ">
                Hemos recibido tu reclamación, en breve nos pondremos en contacto contigo a través del correo
                <span style="font-weight: 700">{{user.email}}</span>
                para seguir adelante con el proceso.
                <br>

                Puedes reenviar el correo pulsando
                <a href="#" @click.prevent="onResendEmail">aquí</a>

                <ConfirmPopup></ConfirmPopup>

              </p>

            </div>




          </div>
        </div>

      </div>




    </div>



  </div>

</template>

<script>

import { mapGetters } from 'vuex';
import ApiService from "@/services/api.service";
import ConfirmPopup from "primevue/confirmpopup";
import {SAVE_SIMULATION} from "@/store/actions.type";
import {logInfo} from "@/helpers";



export default {
  name: 'end',
  components: {
    ConfirmPopup
  },
  data: () => ({
  }),

  watch: {
  },


  computed: {
    ...mapGetters(['claim','user']),

  },
  methods: {

    onResendEmail(event) {
      this.confirm('Seguro que quieres reenviar el correo?', event.currentTarget, () => {
        ApiService.post(`api/claim/${this.claim._id}/resend-email`)
        logInfo('Solicitud enviada')
      })
    },

    async confirm(message, target, cb) {
      this.$confirm.require({
        target ,
        message,
        acceptLabel: 'Aceptar',
        rejectLabel: 'Cancelar',
        icon: 'pi pi-exclamation-triangle',
        accept: cb,
      });

    },


  },
  
  async mounted() {

  },



}
</script>
<style>

.claim-text {
  font-family: "reclamatec", serif;
  font-size: 14px;
  color: #757575;
  line-height: 1.325rem;
  font-weight: 300;
}


.claim-title {
  font-family: "reclamatec", serif;
  font-size: 19px;
  color: #333333;
  line-height: 2.625rem;
  font-weight: 600;
}

.invoice-totals {
  margin-top: 2px;
}

.invoices-list{
}

.invoice-parent {
  width: 550px;
}

.my-sim-error{
  padding: 5px 10px;
  background: #ffbcbc2e;
  color: indianred;
}

.total-divider{
  border-bottom: 3px double #ccc;

  /*box-shadow: 0 0 0 14px #ccc;*/
  /*outline: solid 8px #fff;*/
}

.sim-calculator-icon {
  color: #07bf9b;
  font-size: 128px;
}

.sim-calculator-parent{

  border: 1px solid #006167;
  border-radius: 4px;

  /*background: #F7F7F7;*/

}

.days-label{
  margin: 2px 10px;
}

.sim-currency {
  font-family: 'reclamatec', serif;

  font-size: 16px;
}

.invoice-total{
  margin: 2px 0
}
.sim-total-label-true {
  background: #fffbce73;
  padding: 0 4px;
}

.sim-total-label-false {
  background: #c0bfbf14;
  color: #b9b9b9;
  padding: 0 4px;
}

.vf-file-preview-filename {
  font-size: 13px;
  color: cadetblue;
  font-weight: bold;
  max-width: 400px;
}



.simtable thead th,
.simtable tfoot th {
  border: none !important;
}


table caption {
  padding: .5em 0;
}

table.dataTable th,
table.dataTable td {
  text-align: end;
  white-space: nowrap;
}

.simtable td {
  text-align: end;
}

.header-title{
  background: white;
  border: none !important;
}

.p {
  text-align: center;
  padding-top: 140px;
  font-size: 14px;
}



</style>


